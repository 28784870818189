import {
  Button,
  TextInput,
  Textarea,
  createTheme,
  Select,
  Title,
  NumberInput,
  MultiSelect,
  type ExtendComponent,
  ColorInput,
  Switch,
  Table,
  Checkbox,
  deepMerge,
} from "@mantine/core";
import { Calendar, DateTimePicker, TimeInput } from "@mantine/dates";

const defaultInputStyles: ExtendComponent<any> = {
  defaultProps: {
    radius: "md",
    size: "sm",
  },
  styles(theme) {
    return {
      label: {
        fontSize: 12,
        color: theme.colors.gray[7],
        fontWeight: 400,
      },
    };
  },
};

export function getTheme(lang: string) {
  const fontFamily =
    lang === "ar" ? "Tajawal, sans-serif" : "Poppins, sans-serif";
  const fontWeight = lang === "ar" ? 500 : 400;

  return createTheme({
    fontFamily,
    primaryColor: "purple",
    ...(lang === "ar"
      ? {
          fontSizes: {
            sm: "16px",
            md: "18px",
            lg: "20px",
          },
        }
      : {}),
    primaryShade: {
      light: 7,
      dark: 6,
    },
    colors: {
      purple: [
        "#9210BF",
        "#fdedff",
        "#f2d9f7",
        "#e2b1ea",
        "#d186dd",
        "#c362d2",
        "#ba4bcc",
        "#b73fc9",
        "#a131b2",
        "#90299f",
        "#7e1f8c",
      ],
      blue: [
        "#4007C7",
        "#4108C7",
        "#4007C7",
        "#4007C7",
        "#4007C7",
        "#4007C7",
        "#4007C7",
        "#4007C7",
        "#4007C7",
        "#4007C7",
      ],
    },
    components: {
      Title: Title.extend({
        defaultProps: {
          fw: 500,
        },
      }),
      Button: Button.extend({
        defaultProps: {
          radius: "md",
        },
      }),
      TextInput: TextInput.extend(defaultInputStyles),
      NumberInput: NumberInput.extend(defaultInputStyles),
      Textarea: Textarea.extend(defaultInputStyles),
      Select: Select.extend(defaultInputStyles),
      MultiSelect: MultiSelect.extend(defaultInputStyles),
      ColorInput: ColorInput.extend(defaultInputStyles),
      DateTimePicker: DateTimePicker.extend(
        deepMerge(defaultInputStyles, {
          defaultProps: {
            firstDayOfWeek: 0,
            weekendDays: [5, 6],
          },
        }),
      ),
      Calendar: Calendar.extend(
        deepMerge(defaultInputStyles, {
          defaultProps: {
            firstDayOfWeek: 0,
            weekendDays: [5, 6],
          },
        }),
      ),
      TimeInput: TimeInput.extend(defaultInputStyles),
      Checkbox: Checkbox.extend({
        defaultProps: {
          size: "sm",
        },
        styles(theme) {
          return {
            label: {
              fontSize: 14,
              color: theme.colors.gray[7],
              fontWeight: 400,
            },
          };
        },
      }),
      Switch: Switch.extend({
        styles(theme) {
          const _theme = defaultInputStyles.styles?.(theme);

          return {
            ..._theme,
            label: {
              ..._theme.label,
            },
          };
        },
      }),
      Table: Table.extend({
        styles(theme, props, ctx) {
          return {
            th: {
              fontSize: theme.fontSizes.sm,
              fontWeight,
              color: theme.colors.gray[7],
            },
            td: {
              fontSize: theme.fontSizes.sm,
              fontWeight,
              color: theme.colors.gray[7],
            },
          };
        },
      }),
    },
  });
}
